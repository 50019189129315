import { Profile, UUID } from "types";
import { createBlock } from "./create";

// same algorithm as duplicate
// but use template blocks instead of original blocks
export function createBlockFromTemplate(
    profile: Profile,
    templateProfile: Profile,
    templateBlockId: UUID,
    targetParentId: UUID,
    idMap: { [key: string]: string } = {},
) {
    const targetBlocks = profile.blocks || [];
    const templateBlocks = templateProfile.blocks || [];
    const blockIndex = templateBlocks.findIndex(
        block => block.id === templateBlockId,
    );
    if (blockIndex === -1) {
        return [];
    }

    const originalBlock = templateBlocks[blockIndex];

    const { block: newBlock, error } = createBlock({
        ...originalBlock,
        profile: profile.id,
        parent: targetParentId,
        children: [],
    });

    // idMap is used to swap pinned block id
    idMap[originalBlock.id] = newBlock?.id || "";

    if (!newBlock) {
        throw new Error("Could not duplicate block template: " + error);
    }

    // add to parent children
    const parent = targetBlocks.find(block => block.id === targetParentId);
    if (parent) {
        if (!parent.children) {
            parent.children = [];
        }

        const indexOf = parent.children.indexOf(originalBlock.id);

        if (indexOf === -1) {
            parent.children.push(newBlock.id);
        } else {
            parent.children.splice(indexOf + 1, 0, newBlock.id);
        }
    }

    targetBlocks.push(newBlock);
    originalBlock.children?.forEach(childId => {
        createBlockFromTemplate(
            profile,
            templateProfile,
            childId,
            newBlock.id,
            idMap,
        );
    });

    // swap pinned block
    if (newBlock.properties?.pinned_block_id) {
        newBlock.properties.pinned_block_id =
            idMap[originalBlock.properties?.pinned_block_id || ""];
    }

    return targetBlocks;
}
