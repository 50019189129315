import { v4 as uuidv4 } from "uuid";

export type UUID = string;

export type ProfileBlocks = Block[];

export enum BlockType {
    text = "text",
    media = "media",
    links = "links",
    social = "social",
    bio = "bio",
    form = "form",
    carousel = "carousel",
    page = "page",
    embed = "embed",
    faq = "faq",
    hours = "hours",
    address = "address",
    promotions = "promotions",
    menu = "menu",
}

export type Block = {
    id: UUID;
    type: BlockType;
    children?: UUID[];
    parent: UUID;
    properties?: Properties;
    profile: UUID;
    archived: boolean;
};

export type TopLevelProperty = keyof Block;
export type Property = keyof Properties;

// type-guard to check if it's a HydratedBlock type, i.e. also not a Block
export const isHydratedBlock = (
    x: Block | HydratedBlock | null | undefined,
): x is HydratedBlock => typeof x?.parent !== "string" && !!x?.parent?.id;

export interface HydratedBlock extends Omit<Block, "children" | "parent"> {
    children: HydratedBlock[];
    parent: Block | null;
}

export const ParentBlockType = {
    [BlockType.carousel]: BlockType.carousel,
} as const;

export type BlockTree = HydratedBlock[];

// Bio Block
export interface BioBlock {
    bio_style?: BioStyle;
    hide_cover?: boolean;
    hide_logo?: boolean;
    hide_bio?: boolean;
    hide_name?: boolean;
    // bio_use_custom_fields?: boolean;
    bio_custom_title?: string;
    bio_custom_description?: string;
    bio_custom_cover_url?: string;
    bio_custom_profile_url?: string;
}

// Text Block

export interface TextBlock {
    text_body_alignment_horizontal?: HorizontalAlignment;
    text_body_alignment_vertical?: VerticalAlignment;
    text_body?: string; // this should be formatted as html
    background_style?: BlockBackgroundStyle;
}

// Media Block
export interface MediaBlock {
    media_list?: Media[];
    brightness?: number; // 0 - 100
    object_fit?: ObjectFit;
    caption_type?: CaptionType;
    caption_title_text?: string;
    caption_subtitle_text?: string;
    alignment_horizontal?: HorizontalAlignment;
    alignment_vertical?: VerticalAlignment;
    button_url?: string;
    button_text?: string;
    height_mobile?: number;
    height_desktop?: number;
    media_background_color?: string;
}

export interface Media {
    id: UUID;
    url: string;
    type?: "image" | "video" | "gif" | string;
    description?: string; // used for ai prompt
    active: boolean;
    thumbnail_url?: string;
    format: string;
    duration?: number;
    color?: string;
}

// Links Block
export interface LinksBlock {
    links?: Link[];
    button_mode?: ButtonMode;
}

export interface SocialBlock {
    social_links?: SocialLink[];
    button_mode?: ButtonMode;
}

export interface Link {
    id: UUID;
    name: string;
    url: string;
    icon: string;
    active?: boolean;
}

export interface SocialLink {
    id: UUID;
    icon: string;
    url: string;
    active: boolean;
}

// Carousel Block

export interface CarouselBlock {
    autoplay?: boolean;
    autoplay_speed?: AutoplaySpeed;
    height_mobile?: number;
    height_desktop?: number;
}
// Page Block
export interface PageBlock {
    page_name?: string;
    page_url?: string;
    pinned_block_id?: UUID | "";
    custom_url?: string;
    // or perhaps should just have "reserved keywords"
    page_type?: PageType;
    app_type?: AppType;
}

export enum PageType {
    PAGE = "PAGE",
    URL = "URL",
    APP = "APP",
}

// maps to page url
export enum AppType {
    OnlineOrdering = "order",
    Catering = "cater",
    GiftCards = "gift-cards",
    Locations = "locations",
}

// Embed Block
export interface EmbedBlock {
    embed_url?: string;
    embed_title?: string;
    embed_description?: string;
    embed_favicon_url?: string;
    embed_image_url?: string;
    embed_image_color?: string;
    embed_last_updated?: Date;
    rich_embed?: boolean;
    rich_embed_type?: RichEmbedType;
    height_mobile?: number;
    height_desktop?: number;
    background_style?: BlockBackgroundStyle;
}

export enum RichEmbedType {
    YouTube = "YOUTUBE",
    TikTok = "TIKTOK",
}

// FAQ Block
export interface FAQBlock {
    faq_title?: string;
    faq_questions?: Question[];
}
export interface Question {
    id: UUID;
    question: string;
    answer: string;
}

// Form Block

export interface FormBlock {
    form_title?: string;
    form_description?: string;
    form_fields?: FormField[];
    form_button_title?: string;
    form_button_icon?: string;
    form_image_url?: string;
    form_confirmation_title?: string;
    form_confirmation_description?: string;
    form_confetti_image_url?: string;
    form_show_entries?: boolean;
    form_expired_at?: string;
}

export interface FormField {
    id: UUID;
    type: FormFieldType;
    placeholder?: string;
    default_value?: string;
    name: string;
    required: boolean;
    options?: string[];
}

export interface HoursBlock {}

export interface AddressBlock {}

// Promotions Block
export interface PromotionsBlock {
    promotions?: Promotion[];
}

export interface Promotion {
    id: UUID;
    title: string;
    description: string;
    image_url: string;
    active: boolean;
}

// Menu Block
export interface MenuBlock {
    menu_items?: MenuItem[];
}

export interface MenuItem {
    id: UUID;
    name: string;
    description: string;
    price: number;
    image_url: string;
}

// BaseProperties interface
export interface BaseProperties {
    title?: string;
    full_width?: boolean;
    // for desktop only
    half_width_on_desktop?: boolean;
    padding_top?: number;
    padding_bottom?: number;
}

// Properties interface that extends BaseProperties and other block types
export interface Properties
    extends BaseProperties,
        MediaBlock,
        TextBlock,
        LinksBlock,
        SocialBlock,
        BioBlock,
        CarouselBlock,
        PageBlock,
        EmbedBlock,
        FAQBlock,
        FormBlock,
        HoursBlock,
        AddressBlock,
        PromotionsBlock,
        MenuBlock {}

// ENUMS

export enum HorizontalAlignment {
    left = "left",
    center = "center",
    right = "right",
}

export enum VerticalAlignment {
    top = "top",
    center = "center",
    bottom = "bottom",
}

export enum BackgroundColorTone {
    default = "default",
    none = "none",
    glass = "glass",
}

export enum CaptionType {
    none = "",
    // headline
    big = "big",
    // caption
    medium = "medium",
    // footer
    small = "small",
}

export enum ObjectFit {
    contain = "contain",
    cover = "cover",
    custom = "custom",
}

export enum BlockBackgroundStyle {
    default = "default",
    none = "none",
    outline = "outline",
    subtle = "subtle",
}

export enum ButtonMode {
    default = "default",
    glass = "glass",
    outline = "outline",
    ghost = "ghost",
    solid = "solid",
}

export enum BioStyle {
    style_1 = "Style 1",
    style_2 = "Style 2",
    style_3 = "Style 3",
    style_4 = "Style 4",
}

export enum FormFieldType {
    text = "text",
    email = "email",
    tel = "tel",
    select = "select",
    date = "date",
    file = "file",
    checkbox = "checkbox",
    radio = "radio",
    textarea = "textarea",
    url = "url",
}
export enum AutoplaySpeed {
    slow = "slow",
    medium = "medium",
    fast = "fast",
}

export type Comment = {
    id: UUID;
    user: UUID;
    profile: UUID;
    content: string;
    created_at: string;
    updated_at: string;
    liked_by_owner: boolean;
    likes: number;
    replies: number;
    downvotes: number;
};

export interface HydratedComment extends Omit<Comment, "user"> {
    user: {
        name: string;
        avatar: string;
        username: string;
    };
}

export const DEFAULT_BLOCK_PROPERTIES_GLOBAL: Properties = {
    padding_top: 4,
    padding_bottom: 4,
};

export const DEFAULT_BLOCK_PROPERTIES: {
    [type in BlockType]: Properties;
} = {
    [BlockType.text]: {
        text_body: "<p>Text...</p>",
    },
    [BlockType.media]: {
        height_desktop: 720,
        height_mobile: 420,
        full_width: true,
    },
    [BlockType.links]: {
        links: [
            {
                id: uuidv4(),
                name: "Link...",
                icon: "link",
                url: "https://",
            },
        ],
    },
    [BlockType.social]: {
        social_links: [
            {
                id: uuidv4(),
                icon: "twitter",
                url: "https://",
                active: true,
            },
        ],
    },
    [BlockType.bio]: {},
    [BlockType.carousel]: {},
    [BlockType.embed]: {},
    [BlockType.faq]: {
        faq_title: "Frequently Asked Questions",
        faq_questions: [
            {
                id: uuidv4(),
                question: "Question...",
                answer: "<p>Answer...</p>",
            },
        ],
    },

    // not used
    [BlockType.page]: {},
    [BlockType.form]: {
        form_title: "Contact Us",
        form_button_title: "Submit",
        form_fields: [
            {
                id: uuidv4(),
                type: FormFieldType.text,
                name: "Name",
                required: true,
            },
            {
                id: uuidv4(),
                type: FormFieldType.email,
                name: "Email",
                required: true,
            },
        ],
    },
    [BlockType.hours]: {},
    [BlockType.address]: {},
    [BlockType.promotions]: {},
    [BlockType.menu]: {},
};
