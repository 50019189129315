import {
    BioStyle,
    BlockBackgroundStyle,
    BlockType,
    ButtonMode,
    CaptionType,
    HorizontalAlignment,
    Property,
    VerticalAlignment,
} from "types";
import {
    formIcons,
    Icon,
    IconsMap,
    linkIcons,
    socialIcons,
} from "components/ui/Icon";
import { BLOCK_OPTIONS } from "utilities/constants";
import { cleanName } from "utilities/cleanName";

// order of tabs is determined by this array
export enum PropertyTab {
    // Media
    Media = "Media",
    MediaStyle = "Media Style",
    Title = "Title",
    Caption = "Caption",
    Overlay = "Overlay",
    BackgroundColor = "Background Color",
    // Pin = "Pin",
    Duplicate = "Duplicate",
    Delete = "Delete",
    Type = "Type",
    Text = "Text",
    Color = "Color",
    Links = "Links",
    Social = "Social",
    Carousel = "Carousel Items",
    Embed = "Embed",
    FAQ = "FAQ",
    Form = "Form",
    Bio = "Content Info",
    Advanced = "Advanced",
    Autoplay = "Autoplay",
    Style = "Style",
    Layout = "Layout",
    Block = "Block",
}

enum Headers {
    EditCarouselItem = "Edit Carousel Item",
    EditBlock = "Edit Block",
}

export const HeaderText = {
    Headers,
    PropertyTab,
};

export enum InputType {
    BlockSelector = "block_selector",
    Text = "text",
    Numeric = "numeric",
    Embed = "embed",
    Toggle = "toggle",
    Select = "select",
    Slider = "slider",
    Alert = "alert",
    ColorSelect = "color_select",
    ColorPopover = "color_popover",
    Upload = "upload",
    Radio = "radio",
    Links = "links",
    SocialLinks = "social_links",
    TextEditor = "text_editor",
    Newsletter = "newsletter",
    Switch = "switch",
    Dnd = "dnd",
    MediaContent = "media_content",
    FAQ = "faq",
    FormFields = "form_fields",
    Icon = "icon",
    Image = "image",
    DateTime = "datetime",
    MediaBackgroundColor = "media_background_color",
    AdjustHeight = "adjust_height",
}

export type PropertyItem = {
    name: string;
    blocks: (BlockType | "all")[];
    type?: InputType;
    options?: PropertyOption[];
    hidden?: boolean;
    isTopLevel?: boolean;
    description?: string;
    tab: PropertyTab;
};

export type PropertyItemWithKey = PropertyItem & { key: Property };

export type PropertyOption = {
    value: string;
    label: string;
    icon?: Icon;
};

export const propertyMap: { [key: string]: PropertyItem } = {
    type: {
        name: "Block Type",
        blocks: ["all"],
        type: InputType.BlockSelector,
        tab: PropertyTab.Block,
        options: BLOCK_OPTIONS as PropertyOption[],
        isTopLevel: true,
    },
    title: {
        name: "Title",
        blocks: ["all"],
        type: InputType.Text,
        tab: PropertyTab.Block,
    },
    media_list: {
        name: "Media",
        blocks: [BlockType.media],
        type: InputType.MediaContent,
        tab: PropertyTab.Media,
    },

    // this could be either height_mobile or height_desktop, but it doesn't matter since
    // incrementHeight/decrementHeight from useCellEditor updates the correct height
    // property based on breakpoints
    height_mobile: {
        name: "Height",
        blocks: [BlockType.carousel, BlockType.embed, BlockType.media],
        type: InputType.AdjustHeight,
        tab: PropertyTab.Layout,
    },

    object_fit: {
        name: "Size",
        blocks: [BlockType.media],
        type: InputType.Toggle,
        options: [
            { value: "contain", label: "Fit", icon: "compress_diagonal" },
            { value: "cover", label: "Fill", icon: "expand_diagonal" },
            // { value: "crop", label: "Crop", icon: "crop" },
        ],
        tab: PropertyTab.MediaStyle,
    },
    text_body: {
        name: "Body",
        type: InputType.TextEditor,
        blocks: [BlockType.text],
        tab: PropertyTab.Text,
    },

    caption_type: {
        name: "Caption Style",
        blocks: [BlockType.media],
        type: InputType.Toggle,
        options: [
            { value: CaptionType.none, label: "None" },
            { value: CaptionType.small, label: "Footer" },
            { value: CaptionType.medium, label: "Caption" },
            { value: CaptionType.big, label: "Headline" },
        ],
        tab: PropertyTab.Caption,
    },
    caption_title_text: {
        name: "Caption Title",
        blocks: [BlockType.media],
        type: InputType.Text,
        tab: PropertyTab.Caption,
    },
    caption_subtitle_text: {
        name: "Caption Subtitle",
        blocks: [BlockType.media],
        type: InputType.Text,
        tab: PropertyTab.Caption,
    },
    button_text: {
        name: "Button Label",
        blocks: [BlockType.media],
        type: InputType.Text,
        tab: PropertyTab.Caption,
    },
    button_url: {
        name: "Button Link",
        blocks: [BlockType.media],
        type: InputType.Text,
        tab: PropertyTab.Caption,
    },

    alignment_horizontal: {
        name: "Align Horizontal",
        blocks: [BlockType.media],
        type: InputType.Toggle,
        options: [
            {
                value: HorizontalAlignment.left,
                label: "Left",
                icon: "align_left",
            },
            {
                value: HorizontalAlignment.center,
                label: "Center",
                icon: "align_center",
            },
            {
                value: HorizontalAlignment.right,
                label: "Right",
                icon: "align_right",
            },
        ],
        tab: PropertyTab.Caption,
    },

    alignment_vertical: {
        name: "Align Vertical",
        blocks: [BlockType.media],
        type: InputType.Toggle,
        options: [
            { value: VerticalAlignment.top, label: "Top", icon: "align_top" },
            {
                value: VerticalAlignment.center,
                label: "Center",
                icon: "align_middle",
            },
            {
                value: VerticalAlignment.bottom,
                label: "Bottom",
                icon: "align_bottom",
            },
        ],
        tab: PropertyTab.Caption,
    },

    text_body_alignment_horizontal: {
        name: "Align Horizontal",
        blocks: [BlockType.text],
        type: InputType.Toggle,
        options: [
            {
                value: HorizontalAlignment.left,
                label: "Left",
                icon: "align_left",
            },
            {
                value: HorizontalAlignment.center,
                label: "Center",
                icon: "align_center",
            },
            {
                value: HorizontalAlignment.right,
                label: "Right",
                icon: "align_right",
            },
        ],
        tab: PropertyTab.Style,
    },

    // text_body_alignment_vertical: {
    //     name: "Align Vertical",
    //     blocks: [BlockType.text],
    //     type: InputType.Toggle,
    //     options: [
    //         { value: VerticalAlignment.top, label: "Top", icon: "align_top" },
    //         {
    //             value: VerticalAlignment.center,
    //             label: "Center",
    //             icon: "align_middle",
    //         },
    //         {
    //             value: VerticalAlignment.bottom,
    //             label: "Bottom",
    //             icon: "align_bottom",
    //         },
    //     ],
    //     tab: PropertyTab.Style,
    // },

    background_style: {
        name: "Background Style",
        blocks: [
            BlockType.text,
            // BlockType.contact,
            // BlockType.newsletter,
            // BlockType.chat,
            BlockType.faq,
            // BlockType.comments,
            BlockType.form,
            BlockType.embed,
        ],
        type: InputType.Select,
        tab: PropertyTab.Style,
        options: [
            { value: BlockBackgroundStyle.default, label: "Default" },
            { value: BlockBackgroundStyle.none, label: "None" },
            { value: BlockBackgroundStyle.outline, label: "Outline" },
            { value: BlockBackgroundStyle.subtle, label: "Subtle" },
        ],
    },
    brightness: {
        name: "Overlay",
        blocks: [BlockType.media],
        type: InputType.Slider,
        tab: PropertyTab.MediaStyle,
    },
    media_background_color: {
        name: "Background Color",
        blocks: [BlockType.media],
        type: InputType.MediaBackgroundColor,
        tab: PropertyTab.MediaStyle,
    },

    links: {
        name: "Links",
        type: InputType.Links,
        blocks: [BlockType.links],
        tab: PropertyTab.Links,
    },

    button_mode: {
        name: "Button Style",
        blocks: [BlockType.links, BlockType.social],
        type: InputType.Select,
        tab: PropertyTab.Style,
        options: [
            { value: ButtonMode.default, label: "Default" },
            { value: ButtonMode.solid, label: "Solid" },
            { value: ButtonMode.glass, label: "Glass" },
            { value: ButtonMode.outline, label: "Outline" },
            { value: ButtonMode.ghost, label: "Ghost" },
        ],
    },

    social_links: {
        name: "Social Links",
        type: InputType.SocialLinks,
        blocks: [BlockType.social],
        tab: PropertyTab.Social,
    },

    faq_title: {
        name: "FAQ Title",
        type: InputType.Text,
        blocks: [BlockType.faq],
        tab: PropertyTab.FAQ,
    },

    faq_questions: {
        name: "Questions",
        type: InputType.FAQ,
        blocks: [BlockType.faq],
        tab: PropertyTab.FAQ,
    },

    embed_url: {
        name: "Embed URL",
        type: InputType.Alert,
        blocks: [BlockType.embed],
        tab: PropertyTab.Embed,
    },

    rich_embed: {
        name: "Rich Embed",
        type: InputType.Switch,
        blocks: [BlockType.embed],
        tab: PropertyTab.Embed,
    },
    full_width: {
        name: "Full Width",
        blocks: ["all"],
        type: InputType.Switch,
        tab: PropertyTab.Layout,
    },

    padding_top: {
        name: "Padding Top",
        blocks: ["all"],
        type: InputType.Select,
        tab: PropertyTab.Layout,
        options: [
            { value: "0", label: "0 rem" },
            { value: "1", label: "1 rem" },
            { value: "2", label: "2 rem" },
            { value: "4", label: "4 rem" },
            { value: "8", label: "8 rem" },
            { value: "16", label: "16 rem" },
            { value: "24", label: "24 rem" },
        ],
    },
    padding_bottom: {
        name: "Padding Bottom",
        blocks: ["all"],
        type: InputType.Select,
        options: [
            { value: "0", label: "0 rem" },
            { value: "1", label: "1 rem" },
            { value: "2", label: "2 rem" },
            { value: "4", label: "4 rem" },
            { value: "8", label: "8 rem" },
            { value: "16", label: "16 rem" },
            { value: "24", label: "24 rem" },
        ],
        tab: PropertyTab.Layout,
    },

    autoplay: {
        name: "Autoplay",
        blocks: [BlockType.carousel],
        type: InputType.Switch,
        tab: PropertyTab.Autoplay,
    },
    autoplay_speed: {
        name: "Autoplay Speed",
        blocks: [BlockType.carousel],
        type: InputType.Toggle,
        options: [
            { value: "slow", label: "Slow" },
            { value: "medium", label: "Medium" },
            { value: "fast", label: "Fast" },
        ],
        tab: PropertyTab.Autoplay,
    },

    //forms
    form_title: {
        name: "Form Title",
        blocks: [BlockType.form],
        type: InputType.Text,
        tab: PropertyTab.Form,
    },
    form_description: {
        name: "Form Description",
        blocks: [BlockType.form],
        type: InputType.Text,
        tab: PropertyTab.Form,
    },
    form_button_title: {
        name: "Button Title",
        blocks: [BlockType.form],
        type: InputType.Text,
        tab: PropertyTab.Form,
    },
    form_fields: {
        name: "Form Fields",
        blocks: [BlockType.form],
        type: InputType.FormFields,
        tab: PropertyTab.Form,
    },

    form_image_url: {
        name: "Form Image",
        blocks: [BlockType.form],
        type: InputType.Image,
        tab: PropertyTab.Advanced,
    },
    form_button_icon: {
        name: "Button Icon",
        blocks: [BlockType.form],
        type: InputType.Icon,
        tab: PropertyTab.Advanced,
    },
    form_show_entries: {
        name: "Show Entries",
        blocks: [BlockType.form],
        type: InputType.Switch,
        tab: PropertyTab.Advanced,
    },
    form_expired_at: {
        name: "Form Deadline",
        blocks: [BlockType.form],
        type: InputType.DateTime,
        tab: PropertyTab.Advanced,
    },
    form_confirmation_title: {
        name: "Confirmation Title",
        blocks: [BlockType.form],
        type: InputType.Text,
        tab: PropertyTab.Advanced,
    },
    form_confirmation_description: {
        name: "Confirmation Message",
        blocks: [BlockType.form],
        type: InputType.Text,
        tab: PropertyTab.Advanced,
    },
    form_confetti_image_url: {
        name: "Confetti Image",
        blocks: [BlockType.form],
        type: InputType.Image,
        tab: PropertyTab.Advanced,
    },

    children: {
        name: "Children",
        blocks: [BlockType.carousel],
        type: InputType.Dnd,
        isTopLevel: true,
        tab: PropertyTab.Carousel,
    },
    // "/" for home page. "/about" for about page
    page_url: {
        name: "Page Url",
        blocks: [BlockType.page],
        type: InputType.Text,
        tab: PropertyTab.Block,
    },
    custom_url: {
        name: "Custom Url",
        blocks: [BlockType.page],
        type: InputType.Toggle,
        tab: PropertyTab.Block,
    },
    bio_style: {
        name: "Content Style",
        blocks: [BlockType.bio],
        type: InputType.Select,
        tab: PropertyTab.Style,
        options: [
            { value: BioStyle.style_1, label: BioStyle.style_1 },
            { value: BioStyle.style_2, label: BioStyle.style_2 },
            { value: BioStyle.style_3, label: BioStyle.style_3 },
            { value: BioStyle.style_4, label: BioStyle.style_4 },
        ],
    },
    bio_custom_title: {
        name: "Title",
        blocks: [BlockType.bio],
        type: InputType.Text,
        tab: PropertyTab.Bio,
    },
    bio_custom_description: {
        name: "Description",
        blocks: [BlockType.bio],
        type: InputType.Text,
        tab: PropertyTab.Bio,
    },
    bio_custom_profile_url: {
        name: "Profile Image",
        blocks: [BlockType.bio],
        type: InputType.Image,
        tab: PropertyTab.Bio,
    },
    bio_custom_cover_url: {
        name: "Cover Image",
        blocks: [BlockType.bio],
        type: InputType.Image,
        tab: PropertyTab.Bio,
    },
    // bio_use_custom_fields: {
    //     name: "Detach from Site Settings",
    //     description:
    //         "For this block, use custom fields instead of the site's settings",
    //     blocks: [BlockType.bio],
    //     type: InputType.Switch,
    //     tab: PropertyTab.Bio,
    // },
    hide_cover: {
        name: "Hide Cover Image",
        blocks: [BlockType.bio],
        type: InputType.Switch,
        tab: PropertyTab.Style,
    },
    hide_logo: {
        name: "Hide Profile Image",
        blocks: [BlockType.bio],
        type: InputType.Switch,
        tab: PropertyTab.Style,
    },
    hide_name: {
        name: "Hide Name",
        blocks: [BlockType.bio],
        type: InputType.Switch,
        tab: PropertyTab.Style,
    },
    hide_bio: {
        name: "Hide Description",
        blocks: [BlockType.bio],
        type: InputType.Switch,
        tab: PropertyTab.Style,
    },
};

export const propertyList = Object.keys(propertyMap).map(key => {
    return {
        key,
        ...propertyMap[key],
    };
});

export function mapIconsToOptions(icons: Icon[]) {
    return (
        icons
            .map(option => {
                const Icon = IconsMap[option];
                return {
                    value: option,
                    label: (
                        <div className="flex flex-row items-center">
                            <Icon size={15} color="current" />{" "}
                            <span className="ml-4">{cleanName(option)}</span>
                        </div>
                    ),
                };
            })
            // replace function here puts none(empty icon) option to the start of the list
            // while persist alphabetical order for other options
            .sort((a, b) =>
                a.value
                    .replace("no_icon", "aaa")
                    .localeCompare(b.value.replace("no_icon", "aaa")),
            )
    );
}

export const linkIconOptions = mapIconsToOptions(linkIcons);
export const socialIconOptions = mapIconsToOptions(socialIcons);
export const formIconOptions = mapIconsToOptions(formIcons);
