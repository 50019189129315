import { DEFAULT_PIN_DIRECTION, DEFAULT_SHAPE } from "utilities/constants";
import { Font, Profile, SnackpassStore, UUID } from "types";
import { getBlocks } from "./getBlocks";
import { Preset, getThemeByPreset } from "utilities/theme";

export function getOutOfBoxProfile(store: SnackpassStore) {
    const theme = getThemeByPreset(Preset.Light);
    const profile: Profile = {
        id: store.id as UUID,
        created_time: new Date().toISOString(),
        updated_time: new Date().toISOString(),
        blocks: [],
        owner: store.id as UUID,
        template: false,
        archived: false,
        // Assuming you have these fields in Settings type
        name: store.name,
        cover_url: store.coverPhoto || "",
        profile_url: store.logoUrl || "",
        description: store.aboutUs || "",
        desktop_pin_block_direction: DEFAULT_PIN_DIRECTION,
        font: Font.vollkorn,
        shape: DEFAULT_SHAPE,
        background_type: theme?.background_type,
        primary: theme?.primary,
        secondary: theme?.secondary,
        tertiary: theme?.tertiary,
        background_color: theme?.background,
    };

    profile.blocks = getBlocks(store, profile);

    return profile;
}
