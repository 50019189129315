// Do not import types to avoid circular dependency
// Theme is the customizable theme. Different from palette.ts
// which is the colors of the app itself.
import chroma from "chroma-js";
import { BackgroundType, CSSVariableName, Font, Shape, Theme } from "types";
import _ from "lodash";

export const DEFAULT_BG_IMAGE =
    "https://res.cloudinary.com/ddhetmqtt/image/upload/v1717531125/mystore_content/bg.png";

export const DEFAULT_BACKGROUND = "#FFFFFF";
export const DEFAULT_PRIMARY = "#0F0F0F";
export const DEFAULT_SECONDARY = "#6A7078";
export const DEFAULT_TERTIARY = "#F2F4F5";
export const DEFAULT_INVERSE = "rgba(15, 15, 15, 1)";
export const DEFAULT_BORDER = "rgba(190, 190, 190, 0.16)";

export const DYNAMIC_PRIMARY = "#ffffff";
export const DYNAMIC_SECONDARY = "rgba(255,255,255,0.75)";
export const DYNAMIC_TERTIARY = "rgba(220,220,220, 0.05)";
export const DYNAMIC_BACKDROP_FILTER = "brightness(1.15) opacity(.05)";
export const DYNAMIC_BORDER = "rgba(255,255,255,0.16)";

export const IMG_PRIMARY = "#F0F0F0";
export const IMG_SECONDARY = "#D7D7D7";
export const IMG_TERTIARY = "rgba(190, 190, 190, 0.16)";

export const HIGH_LUMINANCE_INVERSE = "rgba(240, 240, 240, 1)";
export const HIGH_LUMINANCE_BORDER = "rgba(64, 64, 64, 0.16)";

export enum Preset {
    Light = "Light",
    Dark = "Dark",
    Slate = "Slate",
    Chalice = "Chalice",
    MilkTea = "Milk Tea",
    Currant = "Currant",
    Maroon = "Maroon",
    Sienna = "Sienna",
    Tangerine = "Tangerine",
    Goldenrod = "Goldenrod",
    Vanilla = "Vanilla",
    Canary = "Canary",
    Lime = "Lime",
    Guava = "Guava",
    Sage = "Sage",
    Forest = "Forest",
    Blueberry = "Blueberry",
    Azure = "Azure",
    Fig = "Fig",
    Lavender = "Lavender",
    Blush = "Blush",
    Rose = "Rose",
    none = "",
}

export type PresetType = {
    name: Preset;
    background: string;
    background_gradient: string;
    primary: string;
    secondary: string;
    tertiary: string;
    background_type: BackgroundType;
};

export const presets: PresetType[] = [
    {
        name: Preset.Light,
        background: "#F5F5F5",
        background_gradient: "",
        primary: "#0F0F0F",
        secondary: "#585B5F",
        tertiary: "#FFFFFF",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Dark,
        background: "#000000",
        background_gradient: "",
        primary: "#F0F0F0",
        secondary: "#A0A2A7",
        tertiary: "#1D1E20",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Slate,
        background: "#333333",
        background_gradient: "",
        primary: "#ffffff",
        secondary: "#c3c3c3",
        tertiary: "#3b3b3b",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Chalice,
        background: "#E9EBEE",
        background_gradient: "",
        primary: "#00579c",
        secondary: "#3c6a96",
        tertiary: "#dbe1eb",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.MilkTea,
        background: "#E7E2DA",
        background_gradient: "",
        primary: "#29331F",
        secondary: "#52584B",
        tertiary: "#FBF7EF",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Currant,
        background: "#A31600",
        background_gradient: "",
        primary: "#FFFFFF",
        secondary: "#FFF3F0",
        tertiary: "#B81800",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Maroon,
        background: "#8E1B1B",
        background_gradient: "",
        primary: "#f2e5dd",
        secondary: "#d1bfba",
        tertiary: "#962a25",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Sienna,
        background: "#AB5E27",
        background_gradient: "",
        primary: "#fffee1",
        secondary: "#fdfadf",
        tertiary: "#b36834",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Tangerine,
        background: "#FF9800",
        background_gradient: "",
        primary: "#6c1400",
        secondary: "#7b3811",
        tertiary: "#f68d00",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Goldenrod,
        background: "#DAA520",
        background_gradient: "",
        primary: "#512900",
        secondary: "#614215",
        tertiary: "#d09b00",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Vanilla,
        background: "#FDF2D8",
        background_gradient: "",
        primary: "#685700",
        secondary: "#7e6c25",
        tertiary: "#f5e8c7",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Canary,
        background: "#FAFF71",
        background_gradient: "",
        primary: "#335D00",
        secondary: "#537100",
        tertiary: "#EFF55F",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Lime,
        background: "#D4FF89",
        background_gradient: "",
        primary: "#006800",
        secondary: "#2E7C00",
        tertiary: "#C7F579",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Guava,
        background: "#8BC34A",
        background_gradient: "",
        primary: "#004200",
        secondary: "#295714",
        tertiary: "#7eba39",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Sage,
        background: "#556B2F",
        background_gradient: "",
        primary: "#fffee9",
        secondary: "#f7fbe4",
        tertiary: "#5f733c",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Forest,
        background: "#07311D",
        background_gradient: "",
        primary: "#fcfcfc",
        secondary: "#839c8d",
        tertiary: "#163826",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Blueberry,
        background: "#02434F",
        background_gradient: "",
        primary: "#E2E2E2",
        secondary: "#CBCFD3",
        tertiary: "#064956",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Azure,
        background: "#0074D9",
        background_gradient: "",
        primary: "#ffffff",
        secondary: "#f9f9f9",
        tertiary: "#307ddf",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Fig,
        background: "#331D3E",
        background_gradient: "",
        primary: "#EFCCE2",
        secondary: "#F8E7EF",
        tertiary: "#442A51",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Lavender,
        background: "#E6E6FA",
        background_gradient: "",
        primary: "#2449a1",
        secondary: "#56609d",
        tertiary: "#dbdbf6",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Blush,
        background: "#FFE4E1",
        background_gradient: "",
        primary: "#982329",
        secondary: "#9e4f4a",
        tertiary: "#fcd8d4",
        background_type: BackgroundType.minimal,
    },
    {
        name: Preset.Rose,
        background: "#DB7093",
        background_gradient: "",
        primary: "#ffffff",
        secondary: "#eddada",
        tertiary: "#e27c9c",
        background_type: BackgroundType.minimal,
    },
];
export const presetsMap: Record<Preset, PresetType> = presets.reduce(
    (acc, preset) => {
        acc[preset.name] = preset;
        return acc;
    },
    {} as Record<Preset, PresetType>,
);

export const generateColors = (hexColor: string) => {
    const color = chroma(hexColor);
    const luminance = color.luminance();
    let primary;
    let secondary;
    let tertiary;
    switch (true) {
        case luminance >= 0 && luminance < 0.002:
            primary = chroma("#F0F0F0").hex();
            secondary = chroma("#000000").hex();
            tertiary = chroma("#1A1A1A").hex();
            break;
        case luminance >= 0.002 && luminance < 0.01:
            primary = color.brighten(5.3).desaturate(0.1).hex();
            secondary = color.brighten(4.15).desaturate(0.5).hex();
            tertiary = color.brighten(0.2).desaturate(0.15).hex();
            break;
        case luminance >= 0.01 && luminance < 0.03:
            primary = color.brighten(5).desaturate(0.35).hex();
            secondary = color.brighten(2.5).desaturate(0.5).hex();
            tertiary = color.brighten(0.2).desaturate(0.15).hex();
            break;
        case luminance >= 0.03 && luminance < 0.06:
            primary = color.brighten(4.5).desaturate(0.5).hex();
            secondary = color.brighten(3.2).desaturate(1.2).hex();
            tertiary = color.brighten(0.2).desaturate(0.15).hex();
            break;
        case luminance >= 0.06 && luminance < 0.1:
            primary = color.brighten(4).desaturate(0.5).hex();
            secondary = color.brighten(3).desaturate(1.5).hex();
            tertiary = color.brighten(0.2).desaturate(0.15).hex();
            break;
        case luminance >= 0.1 && luminance < 0.3:
            primary = color.brighten(3.8).desaturate(0.25).hex();
            secondary = color.brighten(3.25).desaturate(1).hex();
            tertiary = color.brighten(0.2).desaturate(0.15).hex();
            break;
        case luminance >= 0.3 && luminance < 0.6:
            primary = color.darken(2.8).saturate(0.25).hex();
            secondary = color.darken(2.25).desaturate(0.5).hex();
            tertiary = color.darken(0.2).saturate(0.2).hex();
            break;
        case luminance >= 0.6 && luminance < 0.9:
            primary = color.darken(3.25).saturate(2.5).hex();
            secondary = color.darken(2.75).saturate(1.5).hex();
            tertiary = color.darken(0.2).saturate(0.2).hex();
            break;
        case luminance >= 0.9 && luminance < 0.98:
            primary = color.darken(3.5).saturate(2.5).hex();
            secondary = color.darken(3).saturate(1.5).hex();
            tertiary = color.darken(0.2).saturate(0.2).hex();
            break;
        case luminance >= 0.98:
            primary = chroma("#0F0F0F").hex();
            secondary = chroma("#6A7078").hex();
            tertiary = chroma("#F2F4F5").hex();
            break;
    }
    return {
        primary,
        secondary,
        tertiary,
    };
};

export function handleSetThemeColors(
    cssVariableName: CSSVariableName,
    value: string,
) {
    document.documentElement.style.setProperty(cssVariableName, value);
}

export const getRandomTheme = (): PresetType => {
    const filter = [
        "Vanilla",
        "Lavender",
        "Blueberry",
        "Milk Tea",
        "Luxe Dark",
        "Chalice",
        "Light",
        "Dark",
    ];
    const allPresets = presets.filter(preset => filter.includes(preset.name));
    const randomIndex = Math.floor(Math.random() * allPresets.length);
    return allPresets[randomIndex];
};

export const getRandomShape = (): Shape => {
    const shapes = Object.values(Shape);
    const randomIndex = Math.floor(Math.random() * shapes.length);
    return shapes[randomIndex];
};

export const checkIsDarkColor = (hexColor: string): boolean => {
    const color = chroma(hexColor);
    const luminance = color.luminance();
    return luminance <= 0.01;
};

export function getThemeByPreset(preset: Preset) {
    return presetsMap[preset];
}
