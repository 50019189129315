import { Block, BlockTree, HydratedBlock } from "types";
import { getTree } from "./getTree";

export function getBlock(blocks: Block[], bid: string, hydrated = false) {
    if (hydrated) {
        const tree = getTree(blocks);
        return findBlockInTree(tree, bid);
    }

    return blocks.find(b => b.id === bid);
}

export function checkNode(
    block: HydratedBlock,
    bid: string,
): HydratedBlock | null {
    if (block.id === bid) {
        return block;
    }
    if (block.children) {
        for (let i = 0; i < block.children.length; i++) {
            const b = checkNode(block.children[i], bid);
            if (b) {
                return b;
            }
        }
    }
    return null;
}

// get hydrated block from block tree
// this is used by block editor to get a hydrated block from block tree
export function findBlockInTree(tree: BlockTree, bid: string) {
    const page = tree.find(b => b.id === bid);
    if (page) {
        return page;
    }
    for (let i = 0; i < tree.length; i++) {
        const b = checkNode(tree[i], bid);
        if (b) {
            return b;
        }
    }
    return null;
}
