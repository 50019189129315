import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "./reduxStore";
import { HydratedBlock, Property, TopLevelProperty } from "../types";
import { PropertyTab } from "components/profile/BlockEditor/properties";

// use Block instead of Hydrated Block for block editor redux state
// to make it easier to update the block from server response

export interface BlockEditorState {
    blockEditorState: {
        block: HydratedBlock | null;
        updates: { property: string; value: any }[];
        fromCarousel: string;
        activeProperty: Property | "";
        activePropertyTab: PropertyTab | undefined;
        propertyEditorPage: boolean;
        loading: boolean;
        showToolbar: boolean;
    };
}

// Initial state
const initialState: BlockEditorState = {
    blockEditorState: {
        block: null,
        updates: [],
        fromCarousel: "",
        activeProperty: "",
        activePropertyTab: PropertyTab.Text,
        propertyEditorPage: false,
        loading: false,
        showToolbar: false,
    },
};

// Actual Slice
export const blockEditorSlice = createSlice({
    name: "blockEditor",
    initialState,
    reducers: {
        clearBlockEditor(state) {
            state.blockEditorState.block = null;
            state.blockEditorState.updates = [];
            state.blockEditorState.loading = false;
        },
        setBlockEditor(state, action: PayloadAction<HydratedBlock>) {
            state.blockEditorState.block = action.payload;
            state.blockEditorState.updates = [];
            state.blockEditorState.fromCarousel = "";
            state.blockEditorState.activePropertyTab = undefined;
        },
        setBlockEditorLoading(state, action) {
            state.blockEditorState.loading = action.payload;
        },
        setShowToolBar(state, action) {
            state.blockEditorState.showToolbar = action.payload;
        },
        updateBlockEditorTopLevel(state, action) {
            const {
                property,
                value,
            }: { property: TopLevelProperty; value: any } = action.payload;

            if (!state.blockEditorState.block) {
                return;
            }
            // topLevel properties handled differently because not in properties
            // @ts-ignore
            state.blockEditorState.block[property] = value;
            state.blockEditorState.updates.push({ property, value });
        },

        updateBlockEditor(
            state,
            action: PayloadAction<{ property: Property; value: any }>,
        ) {
            const { property, value } = action.payload;

            if (!state.blockEditorState.block) {
                return;
            }
            if (!state.blockEditorState.block.properties) {
                state.blockEditorState.block.properties = {};
            }
            state.blockEditorState.block.properties[property] = value;
            state.blockEditorState.updates.push({ property, value });
        },
        resetBlockEditor(state) {
            state.blockEditorState.block = {
                ...state.blockEditorState.block,
            } as HydratedBlock;
            state.blockEditorState.updates = [];
            state.blockEditorState.loading = false;
        },
        setFromCarousel(state, action: PayloadAction<string>) {
            state.blockEditorState.fromCarousel = action.payload;
        },

        setActiveProperty(state, action) {
            state.blockEditorState.activeProperty = action.payload;
        },

        setActivePropertyTab(state, action) {
            state.blockEditorState.activePropertyTab = action.payload;
        },
        setPropertyEditorPage(state, action) {
            state.blockEditorState.propertyEditorPage = action.payload;
        },
    },
});

export const {
    setBlockEditor,
    updateBlockEditor,
    updateBlockEditorTopLevel,
    setBlockEditorLoading,
    setShowToolBar,
    resetBlockEditor,
    clearBlockEditor,
    setFromCarousel,
    setActiveProperty,
    setActivePropertyTab,
    setPropertyEditorPage,
} = blockEditorSlice.actions;

export const selectBlockEditorState = (state: AppState) =>
    state.blockEditor.blockEditorState;

export const selectBlockEditor = createSelector(
    [selectBlockEditorState],
    state => state.block,
);

export const selectBlockUpdates = createSelector(
    [selectBlockEditorState],
    state => state.updates,
);

export const selectFromCarousel = createSelector(
    [selectBlockEditorState],
    state => state.fromCarousel,
);

export const selectActiveProperty = createSelector(
    [selectBlockEditorState],
    state => state.activeProperty,
);

export const selectActivePropertyTab = createSelector(
    [selectBlockEditorState],
    state => state.activePropertyTab,
);

export const selectPropertyEditorPage = createSelector(
    [selectBlockEditorState],
    state => state.propertyEditorPage,
);

export const selectBlockEditorLoading = createSelector(
    [selectBlockEditorState],
    state => state.loading,
);

export const selectSetShowToolBar = createSelector(
    [selectBlockEditorState],
    state => state.showToolbar,
);

export default blockEditorSlice.reducer;
