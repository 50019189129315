// tree structure is the same for mobile and desktop

import { BlockTree, BlockType, Block, HydratedBlock } from "types";

export function getTree(blocks: Block[]): BlockTree {
    // step 1: get pages
    const pages = blocks.filter(b => b.type === BlockType.page);

    // create map of blocks from array
    const blocksMap = blocks.reduce((acc, block) => {
        acc[block.id] = block;
        return acc;
    }, {} as { [key: string]: Block });

    // step 2: for each page, populate children recursively
    const blockTree = pages.map(page => {
        const children = getChildren(page, page.children || [], blocksMap);
        return { ...page, parent: null, children } as HydratedBlock;
    });

    return blockTree;
}

// to prevent infintie recursion in case of malformed data
const ARBITRARY_MAX_DEPTH = 20;

function getChildren(
    parent: Block,
    childrenIds: string[],
    blocksMap: { [key: string]: Block },
    { depth = 0 } = {},
): HydratedBlock[] {
    if (depth > ARBITRARY_MAX_DEPTH) return [];
    if (!childrenIds) return [];
    return childrenIds
        .map(id => {
            const child = blocksMap[id];
            if (!child) return null;
            const children = getChildren(
                child,
                child.children || [],
                blocksMap,
                {
                    depth: depth + 1,
                },
            );
            return {
                ...child,
                parent,
                children,
            } as HydratedBlock;
        })
        .filter(c => c !== null) as HydratedBlock[];
}
