import { Block } from "types";
import { BlockSchema } from "./schema";
import { validate } from "./validation";

// function to check if two arrays have the same string elements but could be in a different order
function arraysEqual(a0: string[], b0: string[]) {
    const a = [...a0];
    const b = [...b0];
    if (a === b) return true;
    if (a.length !== b.length) return false;

    a.sort();
    b.sort();

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export function updateBlock(
    profileBlocks: Block[],
    bid: string,
    updates: Partial<Block>,
) {
    // update block
    const index = profileBlocks.findIndex(b => b.id === bid);
    if (index === -1) {
        throw new Error("Block not found");
    }

    const restrictedFields: (keyof Block)[] = ["id", "parent", "profile"];

    restrictedFields.forEach(field => {
        if (updates[field]) {
            throw new Error(`Cannot update ${field}`);
        }
    });

    const oldBlock = profileBlocks[index];

    const body = {
        ...oldBlock,
        ...updates,
        properties: {
            ...oldBlock.properties,
            ...updates.properties,
        },
        last_edited_time: Date(),
    };
    const safeParsed = BlockSchema.safeParse(body);

    if (!safeParsed.success) {
        throw new Error("Invalid block could not update: " + safeParsed.error);
    }

    if (updates["children"]) {
        // check if same ids
        // allow re-ordering, but not adding or removing children
        const oldChildren = oldBlock.children || [];
        const newChildren = updates["children"];

        if (!arraysEqual(oldChildren, newChildren)) {
            throw new Error("Cannot add or remove children");
        }
    }

    const { data: newBlock }: { data: Block } = safeParsed;
    validate(profileBlocks, newBlock);

    profileBlocks[index] = newBlock;

    return {
        newBlocks: profileBlocks,
        oldBlock,
        newBlock,
    };
}
