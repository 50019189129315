// this function handles appending a block to the block tree
// including the children array
import { Block } from "types";
import { validate } from "./validation";

// profileBlocks is the blocks array from the profile
export function appendBlock(profileBlocks: Block[], block: Block) {
    // append to target parent
    const targetParent = profileBlocks.find(b => b.id === block.parent);

    validate(profileBlocks, block);

    // if no target parent, append to profile
    if (!targetParent) {
        profileBlocks.push(block);
        return block;
    }

    // if target parent, append to target parent

    // if no children, create children array
    if (!targetParent.children) {
        targetParent.children = [];
    }

    // append to children
    targetParent.children.push(block.id);

    // append block to profile
    profileBlocks.push(block);

    return profileBlocks;
}
