import { Block } from "types";
import { createBlock } from "./create";

// even though a block contains a parent id, we still have this parameter so
// we can call this function recursively with the new parent ids
// profileBlocks is the blocks array from the profile

// REMEMBER: update the useBlockTempalte function too if this changes!
// TODO — unify this and createBlockFromTemplate
// potentially requires using a HydratedBlock and rescurisvely duplicating the children
export function duplicateBlock(
    profileBlocks: Block[],
    blockId: string,
    parentId: string | "",
    idMap: { [key: string]: string } = {},
) {
    const blockIndex = profileBlocks.findIndex(block => block.id === blockId);
    if (blockIndex === -1) {
        return [];
    }

    const originalBlock = profileBlocks[blockIndex];

    const { block: newBlock } = createBlock({
        ...originalBlock,
        parent: parentId,
        children: [],
    });

    if (!newBlock) {
        throw new Error("Could not duplicate block");
    }

    // idMap is used to swap pinned block id
    idMap[originalBlock.id] = newBlock.id;

    // add to parent children
    const parent = profileBlocks.find(block => block.id === parentId);
    if (parent) {
        if (!parent.children) {
            parent.children = [];
        }

        const indexOf = parent.children.indexOf(originalBlock.id);

        if (indexOf === -1) {
            parent.children.push(newBlock.id);
        } else {
            parent.children.splice(indexOf + 1, 0, newBlock.id);
        }
    }

    profileBlocks.push(newBlock);
    originalBlock.children?.forEach(childId => {
        duplicateBlock(profileBlocks, childId, newBlock.id, idMap);
    });

    // swap pinned block
    if (newBlock.properties?.pinned_block_id) {
        newBlock.properties.pinned_block_id =
            idMap[originalBlock.properties?.pinned_block_id || ""];
    }

    return profileBlocks;
}
