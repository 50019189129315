import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "./reduxStore";

// Type for our state
export interface AdminMenuState {
    editMode: boolean;
    mobilePreviewMode: boolean;
}

// Initial state
const initialState: AdminMenuState = {
    editMode: true,
    // mobile preview mode is when the user is on desktop and we show the user the mockup phone
    mobilePreviewMode: false,
};

// Actual Slice
export const adminMenuSlice = createSlice({
    name: "adminMenu",
    initialState,
    reducers: {
        setEditMode(state, action: PayloadAction<boolean>) {
            state.editMode = action.payload;
        },
        setMobilePreviewMode(state, action: PayloadAction<boolean>) {
            state.mobilePreviewMode = action.payload;
        },
    },
});

export const { setEditMode, setMobilePreviewMode } = adminMenuSlice.actions;

export const selectEditMode = (state: AppState) => state.adminMenu.editMode;
export const selectMobilePreviewMode = (state: AppState) =>
    state.adminMenu.mobilePreviewMode;

export default adminMenuSlice.reducer;
