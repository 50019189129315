import { createSelector, createSlice } from "@reduxjs/toolkit";
import { AppState } from "./reduxStore";
import { IUser } from "@snackpass/snackpass-types";

// Type for our state
export interface UserState {
    userState: IUser | null;
}

// Initial state
const initialState: UserState = {
    userState: null,
};

// Actual Slice
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        // Action to set the authentication status
        setUser(state, action) {
            state.userState = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: AppState) => state.user.userState;

export const selectIsSuperUser = createSelector(
    [selectUser],
    user => user?.snackpassPermissions.isSnackpassEmployee,
);

export default userSlice.reducer;
