import { createSelector, createSlice } from "@reduxjs/toolkit";
import { AppState } from "./reduxStore";

export interface SystemModeState {
    darkMode: boolean;
}

// Initial state
const initialState: SystemModeState = {
    darkMode: false,
};

// Actual Slice
export const systemModeSlice = createSlice({
    name: "systemMode",
    initialState,
    reducers: {
        setSystemMode(state, action) {
            state.darkMode = action.payload;
        },
    },
});

export const { setSystemMode } = systemModeSlice.actions;

export const selectSystemMode = (state: AppState) => state.systemMode.darkMode;

export default systemModeSlice.reducer;
