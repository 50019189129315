// Warning
// Do not access types otherwise you will get a circular dependency error
import {
    BlockIcon,
    CartIcon,
    ChatIcon,
    ColorSwatchIcon,
    CompressIcon,
    EmptyIcon,
    ExpandIcon,
    InboxIcon,
    InboxUnreadIcon,
    MediaIcon,
    MyStoreIcon,
    ShareIcon,
    SnackpassIcon,
    SnackpassLogoSquare,
} from "components/ui/CustomIcon";
import {
    BiCaptions,
    BiCarousel,
    BiChat,
    BiCodeAlt,
    BiColorFill,
    BiDuplicate,
    BiEnvelope,
    BiExit,
    BiFontSize,
    BiGift,
    BiHide,
    BiHorizontalCenter,
    BiLink,
    BiLoaderAlt,
    BiPaintRoll,
    BiPencil,
    BiPlusCircle,
    BiShoppingBag,
    BiShuffle,
    BiSun,
    BiText,
    BiTrash,
} from "react-icons/bi";
import {
    BsArrowCounterclockwise,
    BsChat,
    BsCheckCircle,
    BsDiscord,
    BsExclamationCircle,
    BsFillLightningFill,
    BsFillPinAngleFill,
    BsLaptop,
    BsPinAngle,
    BsPlay,
    BsSnapchat,
    BsTextParagraph,
    BsThreeDots,
    BsThreeDotsVertical,
} from "react-icons/bs";
import {
    CgArrowAlignH,
    CgArrowAlignV,
    CgChevronUpR,
    CgChevronDownR,
    CgProfile,
} from "react-icons/cg";
import {
    FaBell,
    FaBook,
    FaChartLine,
    FaChevronDown,
    FaChevronLeft,
    FaChevronRight,
    FaChevronUp,
    FaCoffee,
    FaCog,
    FaRegComment,
    FaCompressAlt,
    FaCrown,
    FaDollarSign,
    FaExpandAlt,
    FaFacebook,
    FaIcons,
    FaInstagram,
    FaLinkedin,
    FaLock,
    FaMinus,
    FaPalette,
    FaPhoneAlt,
    FaPinterest,
    FaPlayCircle,
    FaPlus,
    FaQrcode,
    FaRegCheckSquare,
    FaSave,
    FaShopify,
    FaSpotify,
    FaStar,
    FaStore,
    FaTiktok,
    FaTimes,
    FaTrophy,
    FaTshirt,
    FaTwitter,
    FaUnlock,
    FaUserFriends,
    FaVideo,
    FaWeixin,
    FaWhatsapp,
    FaYelp,
    FaYoutube,
} from "react-icons/fa";
import {
    FiActivity,
    FiArrowLeft,
    FiArrowRight,
    FiArrowUpRight,
    FiBell,
    FiCalendar,
    FiChevronLeft,
    FiChevronRight,
    FiClipboard,
    FiClock,
    FiEdit,
    FiGrid,
    FiMapPin,
    FiMove,
} from "react-icons/fi";
import { GiCardboardBoxClosed, GiHamburgerMenu } from "react-icons/gi";
import {
    HiArrowNarrowLeft,
    HiArrowNarrowRight,
    HiCheck,
    HiOutlineChartBar,
    HiOutlineMail,
    HiOutlineSupport,
    HiOutlineUsers,
    HiPencil,
    HiReply,
    HiStop,
    HiTemplate,
} from "react-icons/hi";
import { ImEmbed, ImSoundcloud2 } from "react-icons/im";
import { IoIosDocument } from "react-icons/io";
import {
    IoClose,
    IoColorPaletteOutline,
    IoCrop,
    IoHammer,
    IoHeart,
    IoHeartOutline,
    IoSparkles,
    IoTicketOutline,
} from "react-icons/io5";
import {
    MdAddBox,
    MdDeliveryDining,
    MdDragIndicator,
    MdImageAspectRatio,
    MdLocationPin,
    MdMenuBook,
    MdOutlineInfo,
    MdOutlineReviews,
    MdOutlineVerticalAlignBottom,
    MdOutlineVerticalAlignCenter,
    MdOutlineVerticalAlignTop,
    MdSpeed,
    MdTitle,
    MdUpgrade,
    MdWebAsset,
} from "react-icons/md";
import {
    RiCheckboxCircleFill,
    RiCloseCircleLine,
    RiLinkM,
} from "react-icons/ri";
import { RxMobile } from "react-icons/rx";
import {
    SiAmazon,
    SiAndroid,
    SiApple,
    SiBitcoin,
    SiCashapp,
    SiDoordash,
    SiGithub,
    SiGrubhub,
    SiMedium,
    SiOnlyfans,
    SiPatreon,
    SiPlaystation5,
    SiSteam,
    SiSubstack,
    SiTelegram,
    SiTumblr,
    SiTwitch,
    SiUbereats,
    SiVsco,
    SiXbox,
    SiZelle,
} from "react-icons/si";
import {
    TbAlignCenter,
    TbAlignLeft,
    TbAlignRight,
    TbAntennaBars5,
    TbBoxPadding,
    TbBrightnessUp,
    TbConfetti,
    TbDiscount,
    TbLayoutDashboard,
    TbNews,
    TbSettings,
} from "react-icons/tb";

import {
    AiOutlineCloudUpload,
    AiOutlineColumnHeight,
    AiOutlineColumnWidth,
    AiOutlineContacts,
    AiOutlineDelete,
    AiOutlineEye,
    AiOutlineLeftCircle,
} from "react-icons/ai";

export const IconsMap = {
    add_box: MdAddBox,
    align_bottom: MdOutlineVerticalAlignBottom,
    align_center: TbAlignCenter,
    align_horizontal: CgArrowAlignH,
    align_left: TbAlignLeft,
    align_middle: MdOutlineVerticalAlignCenter,
    align_middle_horizontal: BiHorizontalCenter,
    align_right: TbAlignRight,
    align_top: MdOutlineVerticalAlignTop,
    align_vertical: CgArrowAlignV,
    antenna_bars: TbAntennaBars5,
    android: SiAndroid,
    arrow_counterclockwise: BsArrowCounterclockwise,
    arrow_left: HiArrowNarrowLeft,
    arrow_right: HiArrowNarrowRight,
    aspect_ratio: MdImageAspectRatio,
    bell: FaBell,
    bell_outline: FiBell,
    bitcoin: SiBitcoin,
    brightness: TbBrightnessUp,
    book: FaBook,
    box: GiCardboardBoxClosed,
    buoy: HiOutlineSupport,
    calendar: FiCalendar,
    caption: BiCaptions,
    carousel: BiCarousel,
    cart: CartIcon,
    cash_app: SiCashapp,
    chat_bidirectional: BiChat,
    chat_nofill: BsChat,
    chat: ChatIcon,
    chart: HiOutlineChartBar,
    checkbox: FaRegCheckSquare,
    check: HiCheck,
    chevron_down: FaChevronDown,
    chevron_down_outline: CgChevronDownR,
    chevron_left: FaChevronLeft,
    chevron_right: FaChevronRight,
    chevron_left_thin: FiChevronLeft,
    chevron_right_thin: FiChevronRight,
    chevron_up: FaChevronUp,
    chevron_up_outline: CgChevronUpR,
    circle_check: BsCheckCircle,
    circle_check_fill: RiCheckboxCircleFill,
    circle_close: RiCloseCircleLine,
    circle_left_arrow: AiOutlineLeftCircle,
    clipboard: FiClipboard,
    clock: FiClock,
    close: IoClose,
    code: BiCodeAlt,
    discount: TbDiscount,
    coffee: FaCoffee,
    color_fill: BiColorFill,
    color_swatch: ColorSwatchIcon,
    color_palette: FaPalette,
    color_palette_outline: IoColorPaletteOutline,
    comment: FaRegComment,
    compress_diagonal: FaCompressAlt,
    compress: CompressIcon,
    crop: IoCrop,
    cross: FaTimes,
    crown: FaCrown,
    delivery: MdDeliveryDining,
    discord: BsDiscord,
    doordash: SiDoordash,
    dots_horizontal: BsThreeDots,
    dots_vertical: BsThreeDotsVertical,
    drag: MdDragIndicator,
    duplicate: BiDuplicate,
    edit: FiEdit,
    embed: ImEmbed,
    envelope: BiEnvelope,
    exclamation: BsExclamationCircle,
    exit: BiExit,
    expand_diagonal: FaExpandAlt,
    expand: ExpandIcon,
    facebook: FaFacebook,
    font_size: BiFontSize,
    gear: FaCog,
    gift: BiGift,
    github: SiGithub,
    grid: FiGrid,
    grubhub: SiGrubhub,
    heart: IoHeart,
    heart_outline: IoHeartOutline,
    hide: BiHide,
    height: AiOutlineColumnHeight,
    icons: FaIcons,
    image: MediaIcon,
    inbox_unread: InboxUnreadIcon,
    inbox: InboxIcon,
    info: MdOutlineInfo,
    instagram: FaInstagram,
    apple: SiApple,
    layout: TbLayoutDashboard,
    left_arrow: FiArrowLeft,
    lightning: BsFillLightningFill,
    lineChart: FaChartLine,
    link: BiLink,
    linkedin: FaLinkedin,
    location: MdLocationPin,
    lock: FaLock,
    mail: HiOutlineMail,
    medium: SiMedium,
    menu: MdMenuBook,
    minus: FaMinus,
    move: FiMove,
    my_store: MyStoreIcon,
    news: TbNews,
    only_fans: SiOnlyfans,
    block: BlockIcon,
    padding: TbBoxPadding,
    page: IoIosDocument,
    paint_roll: BiPaintRoll,
    paragraph: BsTextParagraph,
    patreon: SiPatreon,
    pen: HiPencil,
    pen_outline: BiPencil,
    phone: FaPhoneAlt,
    pin_hollow: BsPinAngle,
    pin: BsFillPinAngleFill,
    pinterest: FaPinterest,
    play_circle: FaPlayCircle,
    play: BsPlay,
    playstation: SiPlaystation5,
    plus: FaPlus,
    plus_outline: BiPlusCircle,
    price: FaDollarSign,
    profile: CgProfile,
    qr_code: FaQrcode,
    right_arrow: FiArrowRight,
    save: FaSave,
    share: ShareIcon,
    shirt: FaTshirt,
    shopify: FaShopify,
    shopping_bag: BiShoppingBag,
    snackpass: SnackpassIcon,
    snapchat: BsSnapchat,
    social: FaUserFriends,
    social_outline: HiOutlineUsers,
    soundcloud: ImSoundcloud2,
    sparkle: IoSparkles,
    sparse_link: RiLinkM,
    speed: MdSpeed,
    spinner: BiLoaderAlt,
    spotify: FaSpotify,
    star: FaStar,
    steam: SiSteam,
    stop: HiStop,
    store: FaStore,
    substack: SiSubstack,
    sun: BiSun,
    telegram: SiTelegram,
    template: HiTemplate,
    text: BiText,
    ticket: IoTicketOutline,
    tiktok: FaTiktok,
    title: MdTitle,
    trash: BiTrash,
    trash_outline: AiOutlineDelete,
    trophy: FaTrophy,
    tumblr: SiTumblr,
    twitch: SiTwitch,
    twitter: FaTwitter,
    uber_eats: SiUbereats,
    unlock: FaUnlock,
    upgrade: MdUpgrade,
    upload: AiOutlineCloudUpload,
    upright_arrow: FiArrowUpRight,
    video: FaVideo,
    vsco: SiVsco,
    web: MdWebAsset,
    wechat: FaWeixin,
    whatsapp: FaWhatsapp,
    width: AiOutlineColumnWidth,
    xbox: SiXbox,
    yelp: FaYelp,
    youtube: FaYoutube,
    zelle: SiZelle,
    no_icon: EmptyIcon,
    amazon: SiAmazon,
    hamburger: GiHamburgerMenu,
    review: MdOutlineReviews,
    reply: HiReply,
    contact: AiOutlineContacts,
    confetti: TbConfetti,
    activity: FiActivity,
    mobile: RxMobile,
    laptop: BsLaptop,
    eye: AiOutlineEye,
    hammer: IoHammer,
    map: FiMapPin,
    snackpass_logo_square: SnackpassLogoSquare,
    shuffle: BiShuffle,
    settings: TbSettings,
};

export type Icon = keyof typeof IconsMap;

export const linkIcons: Icon[] = [
    "amazon",
    "apple",
    "android",
    "bell",
    "bitcoin",
    "box",
    "buoy",
    "calendar",
    "cart",
    "cash_app",
    "chat",
    "check",
    "clipboard",
    "clock",
    "coffee",
    "color_palette",
    "crown",
    "delivery",
    "discord",
    "doordash",
    "envelope",
    "eye",
    "facebook",
    "gear",
    "gift",
    "github",
    "grubhub",
    "heart",
    "instagram",
    "lightning",
    "lineChart",
    "link",
    "linkedin",
    "location",
    "mail",
    "medium",
    "menu",
    "news",
    "no_icon",
    "only_fans",
    "patreon",
    "pen",
    "phone",
    "pin",
    "pinterest",
    "play",
    "playstation",
    "price",
    "profile",
    "share",
    "shirt",
    "shopify",
    "shopping_bag",
    "snackpass",
    "snapchat",
    "social",
    "soundcloud",
    "sparkle",
    "spotify",
    "star",
    "steam",
    "store",
    "substack",
    "telegram",
    "template",
    "ticket",
    "tiktok",
    "tumblr",
    "twitch",
    "twitter",
    "uber_eats",
    "vsco",
    "wechat",
    "whatsapp",
    "xbox",
    "yelp",
    "youtube",
    "zelle",
];

export const socialIcons: Icon[] = [
    "amazon",
    "apple",
    "android",
    "bitcoin",
    "calendar",
    "cash_app",
    "clock",
    "delivery",
    "discord",
    "doordash",
    "facebook",
    "github",
    "grubhub",
    "instagram",
    "link",
    "linkedin",
    "mail",
    "medium",
    "only_fans",
    "patreon",
    "phone",
    "pinterest",
    "playstation",
    "shopify",
    "snackpass",
    "snapchat",
    "soundcloud",
    "spotify",
    "steam",
    "store",
    "substack",
    "telegram",
    "ticket",
    "tiktok",
    "tumblr",
    "twitch",
    "twitter",
    "uber_eats",
    "vsco",
    "wechat",
    "whatsapp",
    "xbox",
    "yelp",
    "youtube",
    "zelle",
];

export const formIcons: Icon[] = [
    "confetti",
    "check",
    "gift",
    "trophy",
    "no_icon",
];
