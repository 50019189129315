import { Block, BlockType } from "types";

// this is extra business logic validation that involves multiple blocks
// for block level validation use scheam.ts
export function validate(profileBlocks: Block[], block: Block) {
    // check if page url already exists
    if (block.type === BlockType.page) {
        if (block.parent) {
            throw new Error("Page cannot have a parent");
        }
        const exists = profileBlocks
            .filter(b => b.type === BlockType.page)
            .filter(b => b.id !== block.id)
            .find(b => b.properties?.page_url === block.properties?.page_url);
        if (exists) {
            throw new Error("Page url already exists");
        }
    }
    return;
}
