import { Icon } from "components/ui/Icon";
import {
    BlockType,
    BlurType,
    HorizontalAlignment,
    PinDirection,
    Shape,
    VerticalAlignment,
} from "types";

export const NAVBAR_HEIGHT = 68;

export const ROW_HEIGHT = 8 * 20;
export const MIN_ROW_HEIGHT = ROW_HEIGHT * 2;

export const SLIDE_DURATION = 4000;
export const DEFAULT_PIN_DIRECTION = PinDirection.right;

export const DEFAULT_SHAPE = Shape.soft;

export const BLOCK_TEMPLATE_ID = "3f065543-8c4b-4801-bd6e-fbafbb934a92";
export const BLOCK_WIDTH_CLASS = "w-full";
export const BLOCK_BOX_SHADOW =
    "0px 16px 20px rgba(0, 0, 0, 0.04), 0px 8px 8px rgba(0, 0, 0, 0.03), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.02)";
export const DEFAULT_BLOCK_HEIGHT = 240;
export const BLOCK_TITLE_HEIGHT = 60;
export const CAROUSEL_CONTROLS_HEIGHT = 56;
export const HEIGHT_INCREMENT = 120;
export const DEFAULT_HORIZONTAL_ALIGNMENT = HorizontalAlignment.left;
export const DEFAULT_VERTICAL_ALIGNMENT = VerticalAlignment.center;
export const DEFAULT_BLUR = 100;
export const DEFAULT_BLUR_TYPE = BlurType.darken;
export const MOBILE_PREVIEW_WIDTH = 400;
export const BLOCK_OPTIONS: {
    value: BlockType;
    label: string;
    icon: Icon;
}[] = [
    { value: BlockType.media, label: "Media", icon: "image" },
    { value: BlockType.text, label: "Text", icon: "paragraph" },
    { value: BlockType.links, label: "Links", icon: "link" },
    { value: BlockType.social, label: "Social", icon: "instagram" },
    { value: BlockType.bio, label: "Content", icon: "caption" },
    // {
    //     value: BlockType.chat,
    //     label: "Chat",
    //     icon: "chat_bidirectional",
    // },
    { value: BlockType.carousel, label: "Carousel", icon: "carousel" },
    // currently do not allow creating page from block. use add page instead
    // { value: BlockType.page, label: "Page", icon: "page" },
    // { value: BlockType.newsletter, label: "Newsletter", icon: "news" },
    { value: BlockType.embed, label: "Embed", icon: "embed" },
    // { value: BlockType.comments, label: "Comments", icon: "review" },
    // { value: BlockType.form, label: "Form", icon: "contact" },
    { value: BlockType.faq, label: "FAQ", icon: "buoy" },
    // { value: BlockType.hours, label: "Hours", icon: "clock" },
    // { value: BlockType.address, label: "Address", icon: "location" },
    // { value: BlockType.menu, label: "Menu", icon: "menu" },
    // { value: BlockType.promotions, label: "Promotions", icon: "discount" },
];

export const DEFAULT_BOTTOM_SHEET_ANIMATION = {
    stiffness: 300,
    damping: 20,
    mass: 0.1,
};

// Important Note: you'll want to use these default options anywhere useInView() hook
// is called, b/c the react-intersection-observer package optimizes to use only one
// instance of IntersectionObserver if possible based on these options
// element is considered in view if half of it is visible on the screen
export const DEFAULT_IN_VIEW_OPTIONS = {
    threshold: 0.5,
};

export const QUICK_START_TEXT = `<h2>Quick Start</h2><p><br></p><p>Welcome to snack.site, an easy way to make a beautiful for your brand. Start by adding blocks.</p>`;
