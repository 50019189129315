import { UserInfo } from "firebase-admin/lib/auth/user-record";
import { Block, UUID } from "./blocks";
import { Settings } from "./theme";
import { IHoursSchema, IProductCategory } from "@snackpass/snackpass-types";

export type SnackpassStore = {
    id: string;
    name: string;
    slug?: string;
    coverPhoto?: string | null;
    logoUrl?: string | null;
    thumbnailUrl?: string | null;
    username: string;
    catering: {
        enabled: boolean;
        allowDelivery: boolean;
        orderMinimum: number;
        minLeadTime: number;
        email?: string | null;
    };
    onlineOrderingEnabled: boolean;
    aboutUs?: string | null;
    address: string;
    // addressComponents: IAddress;
    brandColor?: string | null;
    color?: string | null;
    emoji: string;
    hours: IHoursSchema;
    geolocation: {
        type: "Point";
        coordinates: [number, number];
    };
    productCategories: IProductCategory[];
    phoneNumber: string;
    storeVerification?: {
        name: string;
        storeId?: string;
    };
};

export type Profile = {
    id: UUID;
    created_time: string;
    updated_time?: string;
    blocks?: Block[];
    owner: UUID;
    template: boolean;
    archived: boolean;
} & Settings;

export enum AuthState {
    loading = "loading",
    welcome = "welcome",
    auth = "auth",
    complete = "complete",
}
