import { settings_keys } from "db/profile";
import { BlockType, Profile, Settings } from "types";
import { createBlockFromTemplate } from "./createBlockFromTemplate";

// sets the target profile's blocks to the template profile's blocks
export function createProfileFromTemplate(
    targetProfile: Profile,
    templateProfile: Profile,
): Profile {
    // reset target blocks
    targetProfile.blocks = [];

    // duplicate each page
    (templateProfile.blocks || []).forEach(templateBlock => {
        if (templateBlock.type === BlockType.page) {
            createBlockFromTemplate(
                targetProfile,
                templateProfile,
                templateBlock.id,
                "",
            );
        }
    });

    const copy_keys = [...settings_keys];
    const newSettings = copy_keys.reduce((acc: Settings, key) => {
        // default to empty string because firebase update doesn't allow undefined
        // should copy name so that the username is not used for navbar
        //@ts-ignore
        acc[key] = templateProfile[key] || "";
        return acc;
    }, {} as Settings);

    return { ...targetProfile, ...newSettings };
}
