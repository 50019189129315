import "../styles/globals.css";
import "../styles/theme.css";
import "../node_modules/react-resizable/css/styles.css";
import "../styles/slider.css";
import "../styles/textBlock.css";
import "../styles/checkbox.css";
import "../styles/dropdownMenu.css";
import "../styles/tiktokEmbed.css";
import "react-spring-bottom-sheet/dist/style.css";
import { AppProps } from "next/app";
import { Inter, Anek_Latin } from "@next/font/google";
import { store } from "../store/reduxStore";
import { Provider } from "react-redux";
import Script from "next/script";
import { Toaster } from "sonner";
const inter = Inter({ subsets: ["latin"], display: "swap" });
const anek = Anek_Latin({ subsets: ["latin"], display: "swap" });

// TODO: improve loading, use variable fonts
// must be loaded at _app
import {
    zodiak,
    literata,
    satoshi,
    monaSans,
    pilcrowRounded,
} from "../utilities/fonts/index";

import initAuth from "../db/firebase/initAuth";
import { initDatadog } from "../utilities/datadog";
import { IconContext } from "react-icons";

initAuth();
initDatadog();

interface CustomPageProps {}
export default function MyApp({
    Component,
    pageProps,
}: AppProps<CustomPageProps>) {
    return (
        <Provider store={store}>
            <IconContext.Provider value={{ className: "shrink-0" }}>
                <main className={inter.className}>
                    {/* google tag manager */}
                    <Script
                        id="google-tag-manager-2"
                        strategy="afterInteractive"
                    >
                        {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5Q3P5T8');
                    `}
                    </Script>
                    <noscript
                        dangerouslySetInnerHTML={{
                            __html: `<iframe
                            src="https://www.googletagmanager.com/ns.html?id=GTM-5Q3P5T8"
                            height="0"
                            width="0"
                            style="display:none;visibility:hidden"
                        ></iframe>`,
                        }}
                    />
                    {/* End google tag manager block */}

                    <Script id="google-analytics" strategy="lazyOnload">
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                                
                            gtag('config', 'G-TGD7QJGJW8');
                        `}
                    </Script>
                    <Toaster position="top-center" />
                    <Component {...pageProps} />
                    {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
                    <Script
                        id="google-tag-manager"
                        src="https://www.googletagmanager.com/gtag/js?id=G-TGD7QJGJW8"
                        strategy="lazyOnload"
                    />
                </main>
            </IconContext.Provider>
        </Provider>
    );
}
