import { init } from "next-firebase-auth";

export default function initAuth(): void {
    init({
        debug: false,

        authPageURL: "/login",
        appPageURL: "/",
        loginAPIEndpoint: "/api/v0.1/auth/login",
        logoutAPIEndpoint: "/api/v0.1/auth/logout",

        onLoginRequestError: err => {
            // TODO: Handle this
            console.error("onLoginRequestError:", err);
        },
        onLogoutRequestError: err => {
            // TODO: Handle this
            console.error("onLogoutRequestError", err);
        },

        // Server side Firebase config
        // We initialize this manually in db/firebase/firebase.ts
        firebaseAdminInitConfig: {
            credential: {
                projectId:
                    process.env.NEXT_PUBLIC_FIREBASE_SNACKPASS_PROJECT_ID || "",
                clientEmail: process.env.FIREBASE_SNACKPASS_CLIENT_EMAIL || "",
                privateKey: process.env.FIREBASE_SNACKPASS_PRIVATE_KEY
                    ? JSON.parse(process.env.FIREBASE_SNACKPASS_PRIVATE_KEY)
                    : undefined,
            },
            databaseURL: process.env.NEXT_PUBLIC_FIREBASE_SNACKPASS_URL || "",
        },

        // Client side Firebase config
        firebaseClientInitConfig: {
            apiKey: process.env.NEXT_PUBLIC_FIREBASE_SNACKPASS_API_KEY || "",
            authDomain: process.env.NEXT_PUBLIC_FIREBASE_SNACKPASS_AUTH_DOMAIN,
            databaseURL: process.env.NEXT_PUBLIC_FIREBASE_SNACKPASS_URL,
            projectId: process.env.NEXT_PUBLIC_FIREBASE_SNACKPASS_PROJECT_ID,
        },

        cookies: {
            name: "mystore_auth",
            keys: [
                process.env.COOKIE_SECRET_CURRENT,
                process.env.COOKIE_SECRET_PREVIOUS,
            ],
            httpOnly: true,
            maxAge: 12 * 60 * 60 * 24 * 1000, // Twelve days
            overwrite: true,
            path: "/",
            sameSite: "strict",
            secure: process.env.NODE_ENV === "production",
            signed: true,
        },

        onVerifyTokenError: err => {
            // TODO: Handle this
            console.error("onVerifyTokenError:", err);
        },
        onTokenRefreshError: err => {
            // TODO: Handle this
            console.error("onTokenRefreshError:", err);
        },
    });
}
