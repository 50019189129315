// Warning: for the customer, this is represented as Theme and Bio
// Settings relates to other fields that are NOT in the Settings Type
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "./reduxStore";
import { BlurType, Settings } from "../types";
import { settings_keys } from "db/profile";
import { DEFAULT_BLUR } from "utilities/constants";

// Type for our state
export interface SettingsEditorState {
    settingsState: {
        settings: Settings | null;
        newSettings: Settings | null;
        updates: { property: string; value: any }[];
    };
}

// Initial state
const initialState: SettingsEditorState = {
    settingsState: {
        settings: null,
        newSettings: null,
        updates: [],
    },
};

function setSettingsValue<K extends keyof Settings, V extends Settings[K]>(
    value: V,
    obj: Settings,
    prop: K,
) {
    obj[prop] = value;
}

// Actual Slice
export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        clearSettingsEditor(state) {
            state.settingsState.settings = null;
            state.settingsState.newSettings = null;
            state.settingsState.updates = [];
        },
        setSettingsEditor(state, action) {
            const profile = action.payload || {};

            const settings = settings_keys.reduce((acc, key) => {
                setSettingsValue(profile[key], acc, key);
                return acc;
            }, {} as Settings);

            state.settingsState.settings = settings;
            state.settingsState.newSettings = settings;
            state.settingsState.updates = [];
        },
        updateSettingsEditor(
            state,
            action: PayloadAction<{ property: keyof Settings; value: any }>,
        ) {
            const { property, value } = action.payload;

            if (!state.settingsState.newSettings) {
                return;
            }
            setSettingsValue(value, state.settingsState.newSettings, property);
            state.settingsState.updates.push({ property, value });

            // // Set blur type and background image type if not set
            // if (property === "background_image_url") {
            //     if (!state.settingsState.newSettings.blur_type) {
            //         setSettingsValue(
            //             BlurType.darken,
            //             state.settingsState.newSettings,
            //             "blur_type",
            //         );
            //         state.settingsState.updates.push({
            //             property: "blur_type",
            //             value: BlurType.darken,
            //         });
            //     }

            //     if (state.settingsState.newSettings.blur_radius === undefined) {
            //         setSettingsValue(
            //             DEFAULT_BLUR,
            //             state.settingsState.newSettings,
            //             "blur_radius",
            //         );
            //         state.settingsState.updates.push({
            //             property: "blur_radius",
            //             value: DEFAULT_BLUR,
            //         });
            //     }
            // }
        },
        resetSettingsEditor(state, action) {
            state.settingsState.newSettings = {
                ...state.settingsState.settings,
            } as Settings;
            state.settingsState.updates = [];
        },
    },
});

export const {
    setSettingsEditor,
    updateSettingsEditor,
    resetSettingsEditor,
    clearSettingsEditor,
} = settingsSlice.actions;

export const selectSettingsEditorState = (state: AppState) =>
    state.settings.settingsState;

export const selectSettingsEditor = createSelector(
    [selectSettingsEditorState],
    state => state.settings,
);

export const selectNewSettings = createSelector(
    [selectSettingsEditorState],
    state => state.newSettings,
);

export const selectSettingsUpdates = createSelector(
    [selectSettingsEditorState],
    state => state.updates,
);

export default settingsSlice.reducer;
