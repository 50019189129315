import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import logger from "redux-logger";

import { profileSlice } from "./profileSlice";
import { modalSlice } from "./modalsSlice";
import { blockEditorSlice } from "./blockEditor";
import { settingsSlice } from "./settingsEditor";
import { userSlice } from "./userSlice";
import { adminMenuSlice } from "./adminMenuSlice";
// import { inviteSlice } from "./inviteSlice";
import { errorSlice } from "./errorSlice";
// import { teamSlice } from "./teamSlice";
// import { aiSlice } from "./aiSlice";
import { systemModeSlice } from "./systemModeSlice";

const makeStore = () =>
    configureStore({
        reducer: {
            [profileSlice.name]: profileSlice.reducer,
            [modalSlice.name]: modalSlice.reducer,
            [blockEditorSlice.name]: blockEditorSlice.reducer,
            [settingsSlice.name]: settingsSlice.reducer,
            [userSlice.name]: userSlice.reducer,
            [adminMenuSlice.name]: adminMenuSlice.reducer,
            // [inviteSlice.name]: inviteSlice.reducer,
            [errorSlice.name]: errorSlice.reducer,
            // [teamSlice.name]: teamSlice.reducer,
            // [aiSlice.name]: aiSlice.reducer,
            [systemModeSlice.name]: systemModeSlice.reducer,
        },
        devTools: process.env.NODE_ENV !== "production",
        middleware: process.env.NODE_ENV === "production" ? [] : [logger],
    });

export const store = makeStore();

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action
>;
