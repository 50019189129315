import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { AppState } from "store/reduxStore";

//** Error Slice */
interface ErrorState {
    message: string | null;
}

const initialState: ErrorState = {
    message: null,
};

export const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        setError(state, action: PayloadAction<string | null>) {
            state.message = action.payload;
        },
    },
});

//** Error Actions */
export const { setError } = errorSlice.actions;

//** Error Selectors */
const getErrorState = (state: AppState) => state.error;

const getErrorMessage = createSelector([getErrorState], error => error.message);

//** Error State Hooks */
export const useErrorMessage = () => useSelector(getErrorMessage);
