import { createSelector, createSlice } from "@reduxjs/toolkit";
import { getTree } from "controllers/blockTree/getTree";
import { settings_keys } from "db/profile";
import { Block, PageType, Profile, Settings, SnackpassStore } from "types";
import { getOutOfBoxProfile } from "store/profileSlice/getOutOfBoxProfile";
import { AppState } from "store/reduxStore";

// Type for our state
export interface ProfileState {
    profileState: Profile | null;
    page: string;
    snackpassStore: SnackpassStore | null;
}

// Initial state
const initialState: ProfileState = {
    profileState: null,
    page: "",
    snackpassStore: null,
};

// Actual Slice
export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setProfile(state, action) {
            state.profileState = action.payload;
        },

        setActivePage(state, action) {
            // if undefined, default to empty string
            state.page = action.payload || "";
        },
        setIsTemplate(state, action) {
            if (!state.profileState) return;
            state.profileState.template = action.payload;
        },

        setSnackpassStore(state, action) {
            state.snackpassStore = action.payload;
        },
    },
});

export const { setProfile, setActivePage, setIsTemplate, setSnackpassStore } =
    profileSlice.actions;

export const selectSnackpassStore = (state: AppState) =>
    state.profile.snackpassStore;

export const selectSnackpassId = createSelector(
    [selectSnackpassStore],
    (store): string => store?.id || "",
);

export const selectProfileState = (state: AppState) =>
    state.profile.profileState;

export const selectProfileFromSnackpassStore = createSelector(
    [selectSnackpassStore],
    (store): Profile | undefined => {
        if (!store) return undefined;

        return getOutOfBoxProfile(store);
    },
);

export const selectProfile = createSelector(
    [selectProfileState, selectProfileFromSnackpassStore],
    // if no profile, default to profile from snackpass store
    (profileState, profileFromSnackpassStore) =>
        profileState || profileFromSnackpassStore,
);

export const selectProfileId = createSelector(
    [selectProfile],
    profile => profile?.id,
);

export const selectProfilePage = (state: AppState) => state.profile.page;

export const selectUsername = createSelector(
    [selectSnackpassStore],
    store => store?.slug,
);

// Warning: this does not return tree. It returns flat list of unhydrated blocks
export const selectBlocks = createSelector(
    [selectProfile],
    profile => profile?.blocks || [],
);

export const selectBlockTree = createSelector([selectBlocks], blocks =>
    getTree(blocks),
);

export const selectActivePageBlock = createSelector(
    [selectBlockTree, selectProfilePage],
    (tree, page) =>
        tree.find(block => {
            // empty string is home page (eg snack.store/xingfutang)
            // non empty string is sub page (eg snack.store/xingfutang/about)
            const blockPageUrl = block.properties?.page_url || "";
            return blockPageUrl === page;
        }),
);

export const selectIsActivePageIsApp = createSelector(
    [selectActivePageBlock],
    activePageBlock => activePageBlock?.properties?.page_type === PageType.APP,
);

export const selectPinnedBlockId = createSelector(
    selectActivePageBlock,
    b => b?.properties?.pinned_block_id,
);

export const selectIsTemplate = createSelector(
    [selectProfile],
    profile => profile?.template,
);

function setSettingsValue<K extends keyof Settings, V extends Settings[K]>(
    value: V,
    obj: Settings,
    prop: K,
) {
    obj[prop] = value;
}

export const selectSettings = createSelector([selectProfile], profile => {
    return settings_keys.reduce((acc, key) => {
        setSettingsValue(profile?.[key], acc, key);
        return acc;
    }, {} as Settings);
});

export const selectBlocksMap = createSelector([selectProfile], profile => {
    const blocks = profile?.blocks || [];
    return blocks.reduce((ret, item) => {
        ret[item.id] = item;
        return ret;
    }, {} as { [key: string]: Block });
});

export default profileSlice.reducer;
