import { Block, BlockType, UUID } from "types";

export function moveBlock(
    blocks: Block[],
    bid: UUID,
    targetParentId: UUID,
    targetIndex = -1,
) {
    const block = blocks.find(b => b.id === bid);
    if (!block) {
        throw new Error("Block not found");
    }
    // update block

    const targetParent = blocks.find(b => b.id === targetParentId);

    if (!targetParent && block.type !== BlockType.page) {
        throw new Error("Non page blocks must have a parent");
    }

    // delete from current parent
    const currentParent = blocks.find(b => b.children?.includes(block.id));
    if (currentParent) {
        if (!currentParent.children) {
            currentParent.children = [];
        }
        currentParent.children = currentParent.children.filter(
            id => id !== block.id,
        );
    }

    // append to target parent

    // if no target parent, append to profile
    if (!targetParent) {
        // delete from current profile
        blocks = blocks.filter(b => b.id !== block.id);

        if (targetIndex === -1) {
            blocks.push(block);
        } else {
            blocks.splice(targetIndex, 0, block);
        }
        return blocks;
    }

    // if target parent, append to target parent

    // if no children, create children array
    if (!targetParent.children) {
        targetParent.children = [];
    }

    // append to children
    if (targetIndex === -1) {
        targetParent.children.push(block.id);
    } else {
        targetParent.children.splice(targetIndex, 0, block.id);
    }

    return blocks;
}

export function moveBlockUpOrDown(
    blocks: Block[],
    bid: string,
    direction: "up" | "down",
) {
    const block = blocks.find(b => b.id === bid);
    if (!block) {
        throw new Error("Block not found");
    }
    // get current parent
    const currentParent = blocks.find(b => b.children?.includes(block.id));
    if (!currentParent) return blocks;

    // get index of block in parent
    if (!currentParent.children) {
        currentParent.children = [];
    }
    const index = currentParent.children.findIndex(id => id === block.id);
    if (index === -1) return blocks;

    // get index of target block in parent
    const targetIndex =
        direction === "up" ? index - 1 : direction === "down" ? index + 1 : -1;
    if (targetIndex === -1) return blocks;

    // get target block
    const targetBlock = blocks.find(
        b => b.id === (currentParent.children || [])[targetIndex],
    );
    if (!targetBlock) return blocks;

    // swap blocks
    currentParent.children[index] = targetBlock.id;
    currentParent.children[targetIndex] = block.id;

    return blocks;
}
