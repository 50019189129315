import { Profile, Settings } from "types";

export const profile_update_keys: (keyof Profile)[] = [
    "name",
    "cover_url",
    "profile_url",
    "description",
    "font",
    "shape",
    "desktop_pin_block_direction",
    "hide_branding",
    "archived",
    "owner",
    "background_type",
    "background_color",
    "background_preset_name",
    "primary",
    "secondary",
    "tertiary",
];

// same as profile update keys, but different type to avoid type errors
export const settings_keys: (keyof Settings)[] = [
    "name",
    "cover_url",
    "profile_url",
    "description",
    "font",
    "shape",
    "desktop_pin_block_direction",
    "hide_branding",
    "background_type",
    "background_preset_name",
    "background_color",
    "primary",
    "secondary",
    "tertiary",
];
