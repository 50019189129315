import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "./reduxStore";

export enum ModalType {
    BlockEditor = "blockEditor",
    AddBlock = "addBlock",
    ProfileTemplate = "profileTemplate",
}

// Type for our state
export interface ModalState {
    modalState: {
        blockEditor: boolean;
        addBlock: boolean;
        profileTemplate: boolean;
    };
}

// Initial state
const initialState: ModalState = {
    modalState: { blockEditor: false, addBlock: false, profileTemplate: false },
};

// Actual Slice
export const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setModal(
            state,
            action: PayloadAction<{ modal: ModalType; visible: boolean }>,
        ) {
            const newModalState = { ...state.modalState };
            newModalState[action.payload.modal] = action.payload.visible;
            state.modalState = newModalState;
        },
    },
});

export const { setModal } = modalSlice.actions;

export const selectModalState = (state: AppState) => state.modal.modalState;

export default modalSlice.reducer;
