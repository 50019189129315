import { z } from "zod";
import {
    AutoplaySpeed,
    BioStyle,
    BlockBackgroundStyle,
    BlockType,
    ButtonMode,
    CaptionType,
    FormFieldType,
    HorizontalAlignment,
    ObjectFit,
    RichEmbedType,
    VerticalAlignment,
    PageType,
    AppType,
} from "types";

const refinement = (val: { parent?: string; type?: BlockType }) => {
    const { parent, type } = val;
    if (type === BlockType.page) {
        return true;
    }
    return !!parent;
};

export const BlockSchema = z
    .object({
        profile: z.string(),
        type: z.nativeEnum(BlockType).default(BlockType.media),
        parent: z.string().optional().default(""),
        children: z.array(z.string()).optional().default([]),
        archived: z.boolean().optional().default(false),
        created_time: z.string().optional().default(Date()),
        last_edited_time: z.string().nullable().optional().default(null),
        id: z.string(),
        properties: z
            .object({
                title: z.string().optional(),
                page_url: z
                    .string()
                    // lower case alphanumeric, dash, underscore
                    .regex(/^[a-z0-9_-]*$/)
                    .optional(),
                page_name: z.string().optional(),
                custom_url: z.string().optional(),
                alignment_horizontal: z
                    .nativeEnum(HorizontalAlignment)
                    .optional(),
                height_mobile: z.number().optional(),
                height_desktop: z.number().optional(),
                alignment_vertical: z.nativeEnum(VerticalAlignment).optional(),
                text_body: z.string().optional(),
                text_body_alignment_horizontal: z
                    .nativeEnum(HorizontalAlignment)
                    .optional(),
                text_body_alignment_vertical: z
                    .nativeEnum(VerticalAlignment)
                    .optional(),
                media_list: z
                    .array(
                        z.object({
                            id: z.string(),
                            url: z.string(),
                            type: z.string().optional(),
                            active: z.boolean().optional().default(true),
                            thumbnail_url: z.string().optional(),
                            format: z.string().optional().default(""),
                            duration: z.number().optional(),
                            color: z.string().optional(),
                        }),
                    )
                    .optional(),
                brightness: z.coerce.number().optional(),
                object_fit: z.nativeEnum(ObjectFit).optional(),
                caption_type: z.nativeEnum(CaptionType).optional(),
                caption_title_text: z.string().optional(),
                caption_subtitle_text: z.string().optional(),
                button_url: z.string().optional(),
                button_text: z.string().optional(),
                links: z
                    .array(
                        z.object({
                            id: z.string(),
                            url: z.string(),
                            name: z.string().optional().default(""),
                            icon: z.string().optional().default(""),
                            active: z.boolean().optional().default(true),
                        }),
                    )
                    .optional(),
                social_links: z
                    .array(
                        z.object({
                            id: z.string(),
                            url: z.string(),
                            icon: z.string().optional().default(""),
                            active: z.boolean().optional().default(true),
                        }),
                    )
                    .optional(),
                autoplay: z.boolean().optional(),
                autoplay_speed: z.nativeEnum(AutoplaySpeed).optional(),
                full_width: z.boolean().optional(),
                padding_top: z.coerce.number().optional(),
                padding_bottom: z.coerce.number().optional(),
                pinned_block_id: z.string().optional(),
                embed_url: z.string().optional(),
                embed_title: z.string().optional(),
                embed_description: z.string().optional(),
                embed_favicon_url: z.string().optional(),
                embed_image_url: z.string().optional(),
                embed_image_color: z.string().optional(),
                embed_last_updated: z.date().optional(),
                rich_embed: z.boolean().optional(),
                rich_embed_type: z.nativeEnum(RichEmbedType).optional(),
                faq_title: z.string().optional(),
                faq_questions: z
                    .array(
                        z.object({
                            id: z.string(),
                            question: z.string(),
                            answer: z.string(),
                        }),
                    )
                    .optional(),
                form_title: z.string().optional(),
                form_description: z.string().optional(),
                form_fields: z
                    .array(
                        z.object({
                            id: z.string(),
                            type: z.nativeEnum(FormFieldType),
                            name: z.string(),
                            options: z.array(z.string()).optional().default([]),
                            required: z.boolean(),
                        }),
                    )
                    .optional(),
                form_button_title: z.string().optional(),
                form_show_entries: z.boolean().optional(),
                form_button_icon: z.string().optional(),
                form_image_url: z.string().optional(),
                form_confirmation_title: z.string().optional(),
                form_confirmation_description: z.string().optional(),
                form_confetti_image_url: z.string().optional(),
                form_expired_at: z.string().optional(),
                button_mode: z.nativeEnum(ButtonMode).optional(),
                background_style: z
                    .nativeEnum(BlockBackgroundStyle)
                    .default(BlockBackgroundStyle.default)
                    .optional(),
                media_background_color: z.string().optional(),
                bio_style: z
                    .nativeEnum(BioStyle)
                    .default(BioStyle.style_1)
                    .optional(),
                hide_cover: z.boolean().default(false).optional(),
                hide_logo: z.boolean().default(false).optional(),
                hide_bio: z.boolean().default(false).optional(),
                hide_name: z.boolean().default(false).optional(),
                // bio_use_custom_fields: z.boolean().default(false).optional(),
                bio_custom_title: z.string().optional(),
                bio_custom_description: z.string().optional(),
                bio_custom_cover_url: z.string().optional(),
                bio_custom_profile_url: z.string().optional(),
                half_width_on_desktop: z.boolean().optional(),
                page_type: z.nativeEnum(PageType).optional(),
                app_type: z.nativeEnum(AppType).optional(),
            })
            .optional(),
    })
    .refine(refinement, { message: "Non-page blocks must have a parent" });
