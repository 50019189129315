function capitalizeBrandName(str: string = "") {
    switch (str) {
        case "Playstation":
            return "PlayStation";
        case "Only Fans":
            return "OnlyFans";
        case "Whatsapp":
            return "WhatsApp";
        case "Wechat":
            return "WeChat";
        case "Youtube":
            return "YouTube";
        case "Soundcloud":
            return "SoundCloud";
        case "Linkedin":
            return "LinkedIn";
        default:
            return str;
    }
}

function toTitleCase(str: string = "") {
    if (!str || typeof str !== "string") return "";

    return str
        .split(" ")
        .map(word => {
            return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
}

function removeUnderscore(str: string = "") {
    if (!str || typeof str !== "string") return "";

    return str.replace("_", " ");
}

export function cleanName(str: string | null | undefined = "") {
    return capitalizeBrandName(toTitleCase(removeUnderscore(str || "")));
}

// example:
// add_block --> Add Block
// uber_eats --> Uber Eats
