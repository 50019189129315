import { Preset } from "utilities/theme";

export enum Font {
    literata = "literata",
    satoshi = "satoshi",
    zodiak = "zodiak",
    pilcrowRounded = "pilcrowRounded",
    monaSans = "monaSans",
    inter = "inter",
    anekLatin = "anekLatin",
    unbounded = "unbounded",
    bitter = "bitter",
    vollkorn = "vollkorn",
    antonio = "antonio",
    splineSans = "splineSans",
    supreme = "supreme",
    alpino = "alpino",
    bevellier = "bevellier",
    plusJakartaSans = "plusJakartaSans",
    teko = "teko",
    gambetta = "gambetta",
}
export enum CSSVariableName {
    background = "--background",
    primary = "--primary",
    secondary = "--secondary",
    tertiary = "--tertiary",
    inverse = "--inverse",
    border = "--border",
    backdropFilter = "--backdropFilter",
    uiBackground = "--uiBackground",
    uiPrimary = "--uiPrimary",
    uiSecondary = "--uiSecondary",
    uiTertiary = "--uiTertiary",
    uiQuaternary = "--uiQuaternary",
    uiQuinary = "--uiQuinary",
    uiInverse = "--uiInverse",
    uiCritical = "--uiCritical",
    uiSuccess = "--uiSuccess",
    uiBorder = "--uiBorder",
}

export type Theme = {
    mode: DarkMode;
    dark_mode: boolean;
    border_color: string;
    background_color: string;
    button_radius: number;
    block_radius: number;
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    quinary: string;
    inverse: string;
    shape: Shape;

    //THEME3
    background_type: BackgroundType;
    background_preset_name?: Preset;
};

export type UITheme = Omit<
    Theme,
    "background_type" | "background_preset_name"
> & {
    critical: string;
    success: string;
};

export type Settings = {
    name: string;
    cover_url: string;
    profile_url: string;
    description: string;
    shape: Shape;
    font: Font;
    desktop_pin_block_direction: PinDirection;
    hide_branding?: boolean;

    //Theme 3
    background_type: BackgroundType;
    background_color: string;
    background_preset_name?: Preset;
    primary: string;
    secondary: string;
    tertiary: string;
};

export type SettingsProperty = keyof Settings;

export enum PinDirection {
    left = "left",
    right = "right",
}

export enum BlurType {
    normal = "normal",
    darken = "darken",
}

export enum DarkMode {
    light = "light",
    dark = "dark",
}

export enum Shape {
    square = "square",
    soft = "soft",
    round = "round",
}

export enum BackgroundType {
    minimal = "minimal",
    dynamic = "dynamic",
    image = "image",
}

export enum ModalContent {
    settings = "settings",
    background = "background",
    colorPicker = "color_picker",
}

export enum ThemeTabEnum {
    style = "style",
    color = "color",
}

export enum BackgroundTabEnum {
    presets = "presets",
    custom = "custom",
    image = "image",
}
