// create block from body

import { uuidv4 } from "@firebase/util";
import { SafeParseError, z } from "zod";
import { Block } from "types";
import { BlockSchema } from "./schema";

type ReturnType = {
    success: boolean;
    block: Block | null;
    error: string | null;
};

export function createBlock(body: Partial<Block>): ReturnType {
    const safeParsed = BlockSchema.safeParse({
        ...body,
        id: uuidv4(),
        created_time: Date(),
        last_edited_time: null,
    });
    if (!safeParsed.success) {
        return {
            error: (safeParsed as SafeParseError<Partial<Block>>).error.message,
            block: null,
            success: false,
        };
    }

    const { data } = safeParsed;

    return {
        success: true,
        error: null,
        block: data as Block,
    };
}
